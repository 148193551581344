import { useEffect, useState, ReactNode } from "react";

interface DragAndDropProps {
  children: ReactNode;
}

interface MousePosition {
  x: number;
  y: number;
}

const DragAndDrop = ({ children }: DragAndDropProps) => {
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [xTranslate, setXTranslate] = useState<number>(0);
  const [yTranslate, setYTranslate] = useState<number>(0);
  const [initialMousePosition, setInitialMousePosition] =
    useState<MousePosition>({ x: 0, y: 0 });

  const onMouseDown = ({
    clientX,
    clientY,
  }: {
    clientX: number;
    clientY: number;
  }) => {
    setInitialMousePosition({ x: clientX, y: clientY });
    setIsDragging(true);
  };

  useEffect(() => {
    const onMouseMove = (e: MouseEvent) => {
      setXTranslate(xTranslate + e.clientX - initialMousePosition.x);
      setYTranslate(yTranslate + e.clientY - initialMousePosition.y);
    };
    if (isDragging) {
      window.addEventListener("mousemove", onMouseMove);
    }
    return () => window.removeEventListener("mousemove", onMouseMove);
  }, [isDragging, initialMousePosition]);

  useEffect(() => {
    const onMouseUp = () => setIsDragging(false);
    window.addEventListener("mouseup", onMouseUp);
    return () => window.removeEventListener("mouseup", onMouseUp);
  }, []);

  return (
    <div
      style={{ transform: `translate(${xTranslate}px,${yTranslate}px)` }}
      onMouseDown={onMouseDown}
    >
      {children}
    </div>
  );
};

export default DragAndDrop;
