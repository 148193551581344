import React from "react";
import "./App.css";
import DragAndDrop from "./DragAndDrop";

import ReactGA from "react-ga4";
ReactGA.initialize("G-FHLJQ7PFEJ");

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

function App() {
  const [isClosed, setIsClosed] = React.useState<boolean>(false);

  return (
    <>
      <div id="desktop_toolbar">
        <BrevLogoIconBlue />
      </div>

      {/* // <!-- The Desktop Icon --> */}
      <button
        id="fileIcon"
        onDoubleClick={() => {
          setIsClosed(false);
        }}
      >
        <FileIcon />
        <div id="fileName">
          <p>README.md</p>
        </div>
      </button>

      {/* // <!-- The Notepad App --> */}
      <DragAndDrop>
        <div
          id="notepad"
          className={classNames(isClosed ? "hidden" : "visible")}
        >
          <div id="app_toolbar">
            <div id="exit_buttons">
              <div
                id="close"
                className={classNames("circle")}
                onClick={() => {
                  setIsClosed(true);
                }}
              ></div>
              <div className="circle"></div>
              <div className="circle"></div>
            </div>
            <div id="app_title">README.md</div>
            <div className="clear"></div>
          </div>

          {/* <!-- The Body --> */}
          <div className="main">
            <div className="content" contentEditable="false">
              <h2 id="hdr">LLaMa Weights</h2>
              <p id="zaP">
                Falcon came out after we printed the flyers, and honestly, you
                should just use that. Run it here:
                <a
                  href="https://brev.dev/docs/guides/falcon"
                  className="text-blue-500 underline"
                >
                  https://brev.dev/docs/guides/falcon
                </a>{" "}
              </p>
              <p id="zaP" className="mt-10">
                {"p.s. you should see what's on the flash drive"}
              </p>
            </div>
          </div>
        </div>
      </DragAndDrop>
    </>
  );
}

export default App;

const FileIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
    // className="w-6 h-6"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
    />
  </svg>
);
const BrevLogoIconBlue = () => (
  <svg
    width="39.84"
    height="26.56"
    viewBox="0 0 996 664"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M299.673 137.872C299.673 137.872 265.611 84.8476 196.432 36.3251C133.383 -7.90565 41.8178 -21.8638 8.51477 53.0307C-21.4877 120.425 35.4421 163.222 61.16 187.157C71.4819 196.753 116.327 241.305 137.873 298.531C160.177 357.773 150.398 452.05 191.399 517.509C212.043 550.499 258.508 585.695 306.971 598.821C384.821 619.878 411.585 598.821 418.073 602.351C424.561 605.88 456.194 663.989 594.879 663.989C738.382 663.989 790.761 528.619 808.289 447.287C815.464 413.966 920.457 279.83 946.852 254.11C1019.84 183.096 1002 119.623 953.34 101.383C877.488 72.915 741.468 193.314 741.468 193.314C741.468 193.314 746.439 148.481 696.254 132.317C629.75 110.899 597.944 137.14 597.944 137.14C597.944 137.14 602.177 105.614 560.018 88.6881C506.163 67.0691 478.642 92.9196 478.642 92.9196C478.642 92.9196 480.64 68.2523 446.866 64.0909C388.921 56.9514 345.942 84.978 316.872 112.483C298.289 130.031 299.673 137.872 299.673 137.872Z"
      fill="#14ACB6"
    />
    <path
      d="M42.8531 114.598C91.3059 158.448 119.627 172.757 170.714 278.415C207.81 355.145 203.136 404.76 220.592 471.091C252.224 591.29 313.234 497.624 313.234 497.624C313.234 497.624 384.196 404.209 353.21 282.266C333.734 205.707 271.208 214.671 271.208 214.671C244.004 151.99 218.163 100.981 174.374 64.0601C64.5836 -28.3923 4.57871 79.9635 42.8531 114.598Z"
      fill="white"
    />
    <path
      d="M331.573 163.263C331.573 163.263 367.797 248.947 369.212 329.025C371.672 467.895 332.188 501.958 322.532 541.305C317.786 560.628 345.903 599.624 390.501 585.335C426.9 573.683 429.452 421.297 441.599 420.716C458.225 419.924 437.95 560.748 439.979 586.168C442.029 609.23 465.164 612.088 477.013 600.206C494.367 590.69 519.818 446.927 521.889 418.259C525.333 370.599 495.043 357.834 495.043 357.834C495.043 357.834 554.864 334.159 573.796 226.004C585.686 158.039 572.166 131.586 554.864 120.486C507.877 90.3137 461.874 115.723 461.874 115.723C461.874 115.723 457.548 88.1277 439.702 85.5105C416.322 82.0611 376.674 95.3474 349.408 123.915C322.143 152.483 331.573 163.263 331.573 163.263Z"
      fill="white"
    />
    <path
      d="M598.119 163.984C593.209 175.956 498.364 585.585 500.803 607.013C503.243 628.442 556.216 650.131 602.444 632.653C648.673 615.175 651.328 559.704 643.158 536.902C626.758 491.187 578.1 498.066 578.1 498.066L586.034 462.057C600.63 473.158 614.878 477.971 628.941 471.082C648.191 461.666 668.784 391.675 650.959 363.338C642.01 349.109 616.385 349.61 616.385 349.61L632.99 278.266C632.99 278.266 701.667 271.948 717.104 223.546C730.562 181.381 705.449 161.146 683.544 153.214C644.727 139.146 602.988 152.011 598.119 163.984Z"
      fill="white"
    />
    <path
      d="M671.51 290.21C671.787 283.532 730.582 239.592 738.424 245.779C751.421 256.027 714.91 349.773 732.745 354.897C750.58 360.021 774.043 224.541 785.749 203.263C792.494 190.979 903.063 123.726 924.957 123.916C962.094 124.227 976.403 174.986 934.685 216.419C881.968 268.782 779.763 412.785 727.886 512.347C666.242 630.559 656.514 560.478 656.514 533.765C656.514 507.052 670.854 306.545 671.51 290.21Z"
      fill="white"
    />
    <path
      d="M257.491 290.99L283.742 427.663C283.742 427.663 324.671 405.101 315.518 340.184C306.969 279.99 257.491 290.99 257.491 290.99Z"
      fill="#14ACB6"
    />
    <path
      d="M452.432 177.02L446.825 315.047C446.825 315.047 526.213 284.283 526.213 209.278C526.213 154.539 452.432 177.02 452.432 177.02Z"
      fill="#14ACB6"
    />
  </svg>
);
const BrevLogoIcon = () => (
  <svg
    width="39.84"
    height="26.56"
    viewBox="0 0 996 664"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M299.673 137.872C299.673 137.872 265.611 84.8476 196.432 36.3251C133.383 -7.90565 41.8178 -21.8638 8.51477 53.0307C-21.4877 120.425 35.4421 163.222 61.16 187.157C71.4819 196.753 116.327 241.305 137.873 298.531C160.177 357.773 150.398 452.05 191.399 517.509C212.043 550.499 258.508 585.695 306.971 598.821C384.821 619.878 411.585 598.821 418.073 602.351C424.561 605.88 456.194 663.989 594.879 663.989C738.382 663.989 790.761 528.619 808.289 447.287C815.464 413.966 920.457 279.83 946.852 254.11C1019.84 183.096 1002 119.623 953.34 101.383C877.488 72.915 741.468 193.314 741.468 193.314C741.468 193.314 746.439 148.481 696.254 132.317C629.75 110.899 597.944 137.14 597.944 137.14C597.944 137.14 602.177 105.614 560.018 88.6881C506.163 67.0691 478.642 92.9196 478.642 92.9196C478.642 92.9196 480.64 68.2523 446.866 64.0909C388.921 56.9514 345.942 84.978 316.872 112.483C298.289 130.031 299.673 137.872 299.673 137.872Z"
      fill="#0F172A"
    />
    <path
      d="M42.8531 114.598C91.3059 158.448 119.627 172.757 170.714 278.415C207.81 355.145 203.136 404.76 220.592 471.091C252.224 591.29 313.234 497.624 313.234 497.624C313.234 497.624 384.196 404.209 353.21 282.266C333.734 205.707 271.208 214.671 271.208 214.671C244.004 151.99 218.163 100.981 174.374 64.0601C64.5836 -28.3923 4.57871 79.9635 42.8531 114.598Z"
      fill="white"
    />
    <path
      d="M331.573 163.263C331.573 163.263 367.797 248.947 369.212 329.025C371.672 467.895 332.188 501.958 322.532 541.305C317.786 560.628 345.903 599.624 390.501 585.335C426.9 573.683 429.452 421.297 441.599 420.716C458.225 419.924 437.95 560.748 439.979 586.168C442.029 609.23 465.164 612.088 477.013 600.206C494.367 590.69 519.818 446.927 521.889 418.259C525.333 370.599 495.043 357.834 495.043 357.834C495.043 357.834 554.864 334.159 573.796 226.004C585.686 158.039 572.166 131.586 554.864 120.486C507.877 90.3137 461.874 115.723 461.874 115.723C461.874 115.723 457.548 88.1277 439.702 85.5105C416.322 82.0611 376.674 95.3474 349.408 123.915C322.143 152.483 331.573 163.263 331.573 163.263Z"
      fill="white"
    />
    <path
      d="M598.119 163.984C593.209 175.956 498.364 585.585 500.803 607.013C503.243 628.442 556.216 650.131 602.444 632.653C648.673 615.175 651.328 559.704 643.158 536.902C626.758 491.187 578.1 498.066 578.1 498.066L586.034 462.057C600.63 473.158 614.878 477.971 628.941 471.082C648.191 461.666 668.784 391.675 650.959 363.338C642.01 349.109 616.385 349.61 616.385 349.61L632.99 278.266C632.99 278.266 701.667 271.948 717.104 223.546C730.562 181.381 705.449 161.146 683.544 153.214C644.727 139.146 602.988 152.011 598.119 163.984Z"
      fill="white"
    />
    <path
      d="M671.51 290.21C671.787 283.532 730.582 239.592 738.424 245.779C751.421 256.027 714.91 349.773 732.745 354.897C750.58 360.021 774.043 224.541 785.749 203.263C792.494 190.979 903.063 123.726 924.957 123.916C962.094 124.227 976.403 174.986 934.685 216.419C881.968 268.782 779.763 412.785 727.886 512.347C666.242 630.559 656.514 560.478 656.514 533.765C656.514 507.052 670.854 306.545 671.51 290.21Z"
      fill="white"
    />
    <path
      d="M257.491 290.99L283.742 427.663C283.742 427.663 324.671 405.101 315.518 340.184C306.969 279.99 257.491 290.99 257.491 290.99Z"
      fill="#0F172A"
    />
    <path
      d="M452.432 177.02L446.825 315.047C446.825 315.047 526.213 284.283 526.213 209.278C526.213 154.539 452.432 177.02 452.432 177.02Z"
      fill="#0F172A"
    />
  </svg>
);
